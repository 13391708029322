
.about {
    width: 100%;
    display: flex;
    flex-direction: row;
    //margin-top: -5px;
    //margin-bottom: 100px;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .containerabout {
        width: 100%;
        display: flex;
        flex-direction: row;
        @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
        }

    .container-about{
        width: 50%;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
        }

        h1 {
            width: 80%;
            font-size: 30px;
            margin-left: 75px;
            font-weight: 500;
            color: #9a7438;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
                font-size: 20px;
                margin-left: -5px;
            }

        }
        .container-desk {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        .desk {
            width: 80%;
            font-size: 16px;
            color: rgb(84, 84, 84);
            line-height: 1.5;
            @media (max-width: 768px) {
                width: 90%;
                text-align: center;
            }
        }
    }
    .fasilitas-desk {
        width: 100%;
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: center;
        margin-left: 100px;
        margin-top: 15px;
        margin-bottom: 15px;
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-left: 50px;
        }
        
        .fasilitas1 {
            width: 100%;
            .penawaranpoin {
                font-size: 17px;
                padding-top: 10px;
            }
        }
        .fasilitas2 {
            width: 100%;
            .penawaranpoin {
                font-size: 17px;
                padding-top: 10px;
            }
        }
    }
}

    .container-wa{
        width: 100%;
        margin-left: 75px;
        margin-top: 20px;
        @media (max-width: 768px) {
          width: 100%;
          
        }
      .buttonpenawaran {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        //margin-top: 30px;
        //margin-left: 15%;
        background-color: #334442;
        width: 25%;
        
        padding: 12px 30px;
        border: 1px solid #334442; /* Add this line for black border */
        border-color: #334442;
        border-radius: 20px;
        font-size: 16px;
        font-weight: light;
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.3s ease, color 0.3s ease;
        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
          //margin-left: 15%;
          //margin-top: -30px;
          margin-bottom: -20px;
          width: 100%;
  
        }
        &:hover {
          background-color: transparent;
          color: #334442;
          border-color: #334442;
        }
        
      }
    }
    .slider-fasilitas{
        width: 40%;
        margin-top: 75px;
        text-align: center;
        margin-left: 75px;
        @media (max-width: 768px){
          width: 90%;
          margin: 0px;
          margin-left: 20px;;
          margin-top: -30px;
      }
        
    
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
        }
    
        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
    }
}
}