.ruko{
    @media (max-width: 768px){
        margin-top: -50px;
    }
    .container-header1{
        justify-content: center;
        margin-top: 4%;
        font-family: 'poppins' sans-serif;
        font-size: 50px;
        font-weight: 400;
        color: #9a7438;
        text-align: center;
        @media (max-width: 768px){
            margin-top: 20%;
            font-size: 45px;
        }
    }
    .Judul{
        justify-content: center;
        margin-top: 1%;
        font-family: 'poppins' sans-serif;
                font-size: 50px;
                font-weight: 400;
                color: #9a7438;
        @media (max-width: 768px){
            font-size: 25px;
            padding-bottom: 30px;
    
        }
    }
}