.promosumbog {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .promo-sumbog {
        width: 80%;
        display: flex;
        flex-direction: row;
        background-color: #e6d2b9;
        padding: 20px 0px 20px 0px ;
        border-radius: 20px;
        @media (max-width: 768px) {
            width: 95%;
            display: flex;
            flex-direction: column;
        }
        .gambarpromo {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align:center ;
            img {
                width: 75%;
                border-radius: 20px;
                @media (max-width: 768px) {
                    width: 95%;
                    
                }
            }
        }
        .ket-promo {
            width: 100%;

            .judul {
                h1 {
                    font-family: "poppins" sans-serif;
                    font-weight: 400;
                    color: white;
                    text-shadow: 2px 2px 4px black;
                    font-size: 35px;
                    @media (max-width: 768px) {
                        width: 100%;
                        margin-left: 10px;
                        font-size: 30px;
                    }
                }
            }
            .promolist {
                width: 100%;
                font-size: 20px;
                @media (max-width: 768px) {
                    width: 100%;
                    margin-left: 10px;
                    
                }
                .penawaranpoin {
                color: #9a7438;
                padding-top: 8px;
                }
            }
        }
        .buttonpenawaran {
    color: #3e3e3e;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 30px;
    margin-left: 15%;
    background-color: white;
    width: 50%;
    padding: 15px 30px;
    border: 1px solid white; /* Add this line for black border */
    border-color: white;
    border-radius: 25px;
    font-size: 16px;
    font-weight: light;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s ease, color 0.3s ease;
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
      margin-left: 15%;
      //margin-top: -30px;
      margin-bottom: -10px;
      width: 70%;
    }
    &:hover {
      background-color: transparent;
      color: white;
      border-color: white;
    }
  }
    }
}